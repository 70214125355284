import React from "react";
import Map from "../components/map/Map";
import { Navbar } from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import CurrentProjects from "../components/CurrentProjects/CurrentProjects";
import FormHelp from "../components/FormHelp/FormHelp";
import Requisites from "../components/Requisites";

export const Project = () => {
  const homepage = document.getElementById("homepage");

  function openFormHelp() {
    const formHelp = document.getElementById("contact-block");
    formHelp.classList.add("open-form");
    formHelp.classList.remove("close-form");
    homepage.classList.add("blur-background");
  }

  function openRequisites() {
    const formRequisites = document.getElementById("requisitesForm");
    formRequisites.classList.add("open-form");
    formRequisites.classList.remove("close-form");
    homepage.classList.add("blur-background");
  }
  return (
    <div className="page">
      <FormHelp />
      <Requisites />
      <div className="container" id="homepage">
        <Navbar color="menu-white-wrap" />
        <Map />
        <CurrentProjects />
      </div>
      <Footer />
    </div>
  );
};
