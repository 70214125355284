import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { baseUrl } from "../../config";
import "./Reportsblock.css";
import React from "react";
import Calendar from "../../assets/calendar.svg";
import { t } from "i18next";

export default function ReportsAll() {
  return (
    <div className="ReportsAll">
      <h3 className="section-title-text py-5 container">
        <div className="title-line"></div>
        {t("reports page title")}
      </h3>
      <div className="container">
        <Row className="reports-all left mb-5">
          <Col md={5} className="report-item-info">
            <div className="date-post-page">
              <div className="date-post-wrap-page">
                <img src={Calendar} alt="calendar icon"></img>
                <nobr>{t("report data 1")} </nobr>
              </div>
              <div className="location">
                <svg
                  width="20"
                  height="24"
                  viewBox="0 0 20 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 12C8.9 12 8 11.1 8 10C8 8.9 8.9 8 10 8C11.1 8 12 8.9 12 10C12 11.1 11.1 12 10 12ZM16.5 10C16.5 6.37 13.35 3.5 10 3.5C6.65 3.5 3.5 6.37 3.5 10C3.5 12.34 5.95 16.3 10 20C14.05 16.3 16.5 12.34 16.5 10ZM10 2C14.2 2 18 5.22 18 10.2C18 13.52 15.33 17.45 10 22C4.67 17.45 2 13.52 2 10.2C2 5.22 5.8 2 10 2Z"
                    fill="#4C94FF"
                  />
                </svg>
                <nobr>{t("report data location 1")} </nobr>
              </div>
            </div>
            <h3 className="m-5">{t("zvit 1 title")}</h3>
            <p className="mb-1">{t("zvit 1 description")}</p>
            <a
              href="https://www.facebook.com/groups/neboukrainu/posts/969705084019061/"
              className="btn-req-support w-50 mt-5"
              target="_blank"
              rel="noreferrer"
            >
              {t("zvit btn link")}
            </a>
          </Col>
          <Col md={6} className="pics-reports">
            <img
              className="d-block w-100 img-fluid"
              src={baseUrl + "/zvit1.jpg"}
              alt="First slide"
            />
            <img
              className="d-block w-100 img-fluid"
              src={baseUrl + "/zvit1_1.jpg"}
              alt="First slide"
            />
          </Col>
        </Row>
        <Row className="reports-all revers right my-5">
          <Col md={6} className="pics-reports">
            <img
              className="d-block w-100 img-fluid"
              src={baseUrl + "/zvit2.jpg"}
              alt="First slide"
            />
            <img
              className="d-block w-100 img-fluid"
              src={baseUrl + "/zvit2_1.jpg"}
              alt="First slide"
            />
          </Col>
          <Col md={5} className="report-item-info">
            <div className="date-post-page">
              <div className="date-post-wrap-page">
                <img src={Calendar} alt="calendar icon"></img>
                <nobr>{t("report data 2")} </nobr>
              </div>
              <div className="location">
                <svg
                  width="20"
                  height="24"
                  viewBox="0 0 20 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 12C8.9 12 8 11.1 8 10C8 8.9 8.9 8 10 8C11.1 8 12 8.9 12 10C12 11.1 11.1 12 10 12ZM16.5 10C16.5 6.37 13.35 3.5 10 3.5C6.65 3.5 3.5 6.37 3.5 10C3.5 12.34 5.95 16.3 10 20C14.05 16.3 16.5 12.34 16.5 10ZM10 2C14.2 2 18 5.22 18 10.2C18 13.52 15.33 17.45 10 22C4.67 17.45 2 13.52 2 10.2C2 5.22 5.8 2 10 2Z"
                    fill="#4C94FF"
                  />
                </svg>
                <nobr>{t("report data location 2")} </nobr>
              </div>
            </div>
            <h3 className="m-5">{t("zvit 2 title")}</h3>
            <p className="mb-1">{t("zvit 2 description")}</p>
            <a
              href="https://www.facebook.com/groups/neboukrainu/posts/967645974224972/"
              className="btn-req-support w-50 mt-5"
              target="_blank"
              rel="noreferrer"
            >
              {t("zvit btn link")}
            </a>
          </Col>
        </Row>
        <Row className="reports-all left my-5">
          <Col md={5} className="report-item-info">
            <div className="date-post-page">
              <div className="date-post-wrap-page">
                <img src={Calendar} alt="calendar icon"></img>
                <nobr>{t("report data 3")} </nobr>
              </div>
              <div className="location">
                <svg
                  width="20"
                  height="24"
                  viewBox="0 0 20 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 12C8.9 12 8 11.1 8 10C8 8.9 8.9 8 10 8C11.1 8 12 8.9 12 10C12 11.1 11.1 12 10 12ZM16.5 10C16.5 6.37 13.35 3.5 10 3.5C6.65 3.5 3.5 6.37 3.5 10C3.5 12.34 5.95 16.3 10 20C14.05 16.3 16.5 12.34 16.5 10ZM10 2C14.2 2 18 5.22 18 10.2C18 13.52 15.33 17.45 10 22C4.67 17.45 2 13.52 2 10.2C2 5.22 5.8 2 10 2Z"
                    fill="#4C94FF"
                  />
                </svg>
                <nobr>{t("report data location 3")} </nobr>
              </div>
            </div>
            <h3 className="m-5">{t("zvit 3 title")}</h3>
            <p className="mb-1">{t("zvit 3 description")}</p>
            <a
              href="https://www.facebook.com/groups/neboukrainu/posts/804355370554034/"
              className="btn-req-support w-50 mt-5"
              target="_blank"
              rel="noreferrer"
            >
              {t("zvit btn link")}
            </a>
          </Col>
          <Col md={6} className="pics-reports">
            <img
              className="d-block w-100 img-fluid"
              src={baseUrl + "/zvit3.jpg"}
              alt="First slide"
            />
            <img
              className="d-block w-100 img-fluid"
              src={baseUrl + "/zvit3_1.jpg"}
              alt="First slide"
            />
          </Col>
        </Row>
        <Row className="reports-all revers right my-5">
          <Col md={6} className="pics-reports">
            <img
              className="d-block w-100 img-fluid"
              src={baseUrl + "/zvit4.jpg"}
              alt="First slide"
            />
            <img
              className="d-block w-100 img-fluid"
              src={baseUrl + "/zvit4_1.jpg"}
              alt="First slide"
            />
          </Col>
          <Col md={5} className="report-item-info">
            <div className="date-post-page">
              <div className="date-post-wrap-page">
                <img src={Calendar} alt="calendar icon"></img>
                <nobr>{t("report data 4")} </nobr>
              </div>
              <div className="location">
                <svg
                  width="20"
                  height="24"
                  viewBox="0 0 20 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 12C8.9 12 8 11.1 8 10C8 8.9 8.9 8 10 8C11.1 8 12 8.9 12 10C12 11.1 11.1 12 10 12ZM16.5 10C16.5 6.37 13.35 3.5 10 3.5C6.65 3.5 3.5 6.37 3.5 10C3.5 12.34 5.95 16.3 10 20C14.05 16.3 16.5 12.34 16.5 10ZM10 2C14.2 2 18 5.22 18 10.2C18 13.52 15.33 17.45 10 22C4.67 17.45 2 13.52 2 10.2C2 5.22 5.8 2 10 2Z"
                    fill="#4C94FF"
                  />
                </svg>
                <nobr>{t("report data location 4")} </nobr>
              </div>
            </div>
            <h3 className="m-5">{t("zvit 4 title")}</h3>
            <p className="mb-1">{t("zvit 4 description")}</p>
            <a
              href="https://www.facebook.com/groups/neboukrainu/posts/965964951059741/"
              className="btn-req-support w-50 mt-5"
              target="_blank"
              rel="noreferrer"
            >
              {t("zvit btn link")}
            </a>
          </Col>
        </Row>
        <Row className="reports-all left my-5">
          <Col md={5} className="report-item-info">
            <div className="date-post-page">
              <div className="date-post-wrap-page">
                <img src={Calendar} alt="calendar icon"></img>
                <nobr>{t("report data 5")} </nobr>
              </div>
              <div className="location">
                <svg
                  width="20"
                  height="24"
                  viewBox="0 0 20 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 12C8.9 12 8 11.1 8 10C8 8.9 8.9 8 10 8C11.1 8 12 8.9 12 10C12 11.1 11.1 12 10 12ZM16.5 10C16.5 6.37 13.35 3.5 10 3.5C6.65 3.5 3.5 6.37 3.5 10C3.5 12.34 5.95 16.3 10 20C14.05 16.3 16.5 12.34 16.5 10ZM10 2C14.2 2 18 5.22 18 10.2C18 13.52 15.33 17.45 10 22C4.67 17.45 2 13.52 2 10.2C2 5.22 5.8 2 10 2Z"
                    fill="#4C94FF"
                  />
                </svg>
                <nobr>{t("report data location 5")} </nobr>
              </div>
            </div>
            <h3 className="m-5">{t("zvit 5 title")}</h3>
            <p className="mb-1">{t("zvit 5 description")}</p>
            <a
              href="https://www.facebook.com/groups/neboukrainu/posts/965645307758372/"
              className="btn-req-support w-50 mt-5"
              target="_blank"
              rel="noreferrer"
            >
              {t("zvit btn link")}
            </a>
          </Col>
          <Col md={6} className="pics-reports">
            <img
              className="d-block w-100 img-fluid"
              src={baseUrl + "/zvit5.jpg"}
              alt="First slide"
            />
            <img
              className="d-block w-100 img-fluid"
              src={baseUrl + "/zvit5_1.jpg"}
              alt="First slide"
            />
          </Col>
        </Row>
        <Row className="reports-all revers right my-5">
          <Col md={6} className="pics-reports">
            <img
              className="d-block w-100 img-fluid"
              src={baseUrl + "/zvit6.jpg"}
              alt="First slide"
            />
            <img
              className="d-block w-100 img-fluid"
              src={baseUrl + "/zvit6_1.jpg"}
              alt="First slide"
            />
          </Col>
          <Col md={5} className="report-item-info">
            <div className="date-post-page">
              <div className="date-post-wrap-page">
                <img src={Calendar} alt="calendar icon"></img>
                <nobr>{t("report data 6")} </nobr>
              </div>
              <div className="location">
                <svg
                  width="20"
                  height="24"
                  viewBox="0 0 20 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 12C8.9 12 8 11.1 8 10C8 8.9 8.9 8 10 8C11.1 8 12 8.9 12 10C12 11.1 11.1 12 10 12ZM16.5 10C16.5 6.37 13.35 3.5 10 3.5C6.65 3.5 3.5 6.37 3.5 10C3.5 12.34 5.95 16.3 10 20C14.05 16.3 16.5 12.34 16.5 10ZM10 2C14.2 2 18 5.22 18 10.2C18 13.52 15.33 17.45 10 22C4.67 17.45 2 13.52 2 10.2C2 5.22 5.8 2 10 2Z"
                    fill="#4C94FF"
                  />
                </svg>
                <nobr>{t("report data location 6")} </nobr>
              </div>
            </div>
            <h3 className="m-5">{t("zvit 6 title")}</h3>
            <p className="mb-1">{t("zvit 6 description")}</p>
            <a
              href="https://www.facebook.com/groups/neboukrainu/posts/967115354278034/"
              className="btn-req-support w-50 mt-5"
              target="_blank"
              rel="noreferrer"
            >
              {t("zvit btn link")}
            </a>
          </Col>
        </Row>
        <Row className="reports-all left my-5">
          <Col md={5} className="report-item-info">
            <div className="date-post-page">
              <div className="date-post-wrap-page">
                <img src={Calendar} alt="calendar icon"></img>
                <nobr>{t("report data 7")} </nobr>
              </div>
              <div className="location">
                <svg
                  width="20"
                  height="24"
                  viewBox="0 0 20 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 12C8.9 12 8 11.1 8 10C8 8.9 8.9 8 10 8C11.1 8 12 8.9 12 10C12 11.1 11.1 12 10 12ZM16.5 10C16.5 6.37 13.35 3.5 10 3.5C6.65 3.5 3.5 6.37 3.5 10C3.5 12.34 5.95 16.3 10 20C14.05 16.3 16.5 12.34 16.5 10ZM10 2C14.2 2 18 5.22 18 10.2C18 13.52 15.33 17.45 10 22C4.67 17.45 2 13.52 2 10.2C2 5.22 5.8 2 10 2Z"
                    fill="#4C94FF"
                  />
                </svg>
                <nobr>{t("report data location 7")} </nobr>
              </div>
            </div>
            <h3 className="m-5">{t("zvit 7 title")}</h3>
            <p className="mb-1">{t("zvit 7 description")}</p>
            <a
              href="https://www.facebook.com/groups/neboukrainu/posts/964416884547881/"
              className="btn-req-support w-50 mt-5"
              target="_blank"
              rel="noreferrer"
            >
              {t("zvit btn link")}
            </a>
          </Col>
          <Col md={6} className="pics-reports">
            <img
              className="d-block w-100 img-fluid"
              src={baseUrl + "/zvit7.jpg"}
              alt="First slide"
            />
            <img
              className="d-block w-100 img-fluid"
              src={baseUrl + "/zvit7_1.jpg"}
              alt="First slide"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}
