import "./Map.css";
import React, { useEffect, useState } from "react";
import Uk from "@svg-maps/ukraine";
import { SVGMap } from "react-svg-map";
import "react-svg-map/lib/index.css";
import data from "./MapProjects.json";
import Projects from "./Projects";
import Ukraine from "../../assets/ukraine.png";
import { t } from "i18next";

export default function Map() {
  let [stateCode, setStateCode] = useState("kherson");
  let [stateData, setStateData] = useState(data[stateCode]);

  useEffect(() => {
    let kyiv = document.getElementById("kyiv-city");
    let kyivName = document.getElementById("kyivName");

    const handleResize = () => {
      let kyivCoord = kyiv.getBoundingClientRect();
      kyivName.style.left = kyivCoord.left + "px";
      kyivName.style.top = kyivCoord.top + "px";
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  function onLocationClick(event) {
    setStateCode(event.target.id);
    setStateData(data[event.target.id]);
  }

  return (
    <div className="MapHelp">
      <h3 className="section-title-text py-5">
        <div className="title-line"></div>
        {t("map title")}
      </h3>
      <p> {t("map p")}</p>
      <div className="MapUkr">
        <div className="map-pic">
          <div className="ukraine">
            <img src={Ukraine} alt="ukraine" />
          </div>
          <div id="kyivName">{t("Kyiv")}</div>
          <SVGMap map={Uk} onLocationClick={onLocationClick} />
        </div>
        <div className="map-info">
          <h3>{t("map projects title")}</h3>
          <h3>{t(stateCode)}</h3>
          <div className="projects-info">
            <Projects info={stateData} />
          </div>
        </div>
      </div>
    </div>
  );
}
