import React from "react";
import { Navbar } from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import FormHelp from "../components/FormHelp/FormHelp";
import Requisites from "../components/Requisites";
import "../Projects.css";
import { t } from "i18next";
import { baseUrl } from "../config";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export const Project7 = () => {
  function openRequisites() {
    const homepage = document.getElementById("homepage");
    const formRequisites = document.getElementById("requisitesForm");
    formRequisites.classList.add("open-form");
    formRequisites.classList.remove("close-form");
    homepage.classList.add("blur-background");
  }
  return (
    <div className="page">
      <FormHelp />
      <Requisites />
      <Navbar color="menu-white-wrap" />
      <div className="project-head container">
        <div className="left-head-proj">
          <h3 className="section-title-text py-5">{t("name title proj7")}</h3>
          <button className="btn-take-part" onClick={openRequisites}>
            {t("btn get help")}
          </button>
        </div>
        <div className="right-head-proj">
          <img src={baseUrl + "/proj7_1.jpg"} alt="photo help people" />
        </div>
      </div>
      <div className="project-wrap-page container">
        <Row className="project-page-row">
          <Col md className="project-page-col">
            <p>
              <b> {t("The purpose of the project")}</b> {t("goal proj7")}
            </p>
            <p>
              <b> {t("Project implementation period")}</b> {t("time proj7")}
            </p>
            <p>
              <b>{t("proj7 regions title")}</b>
              <ul>
                <li> {t("proj7 regions 1")}</li>
                <li> {t("proj7 regions 2")}</li>
                <li> {t("proj7 regions 3")}</li>
                <li> {t("proj7 regions 4")}</li>
                <li> {t("proj7 regions 5")}</li>
              </ul>
            </p>
          </Col>
          <Col md className="project-page-col grey-block">
            <p>
              <b> {t("Subject of distribution")}</b> {t("subject proj1")}
            </p>
            <ul>
              <li>
                <p>{t("subject1 1 proj7")}</p>
              </li>
              <li>
                <p>{t("subject1 2 proj7")}</p>
              </li>
              <li>
                <p>{t("subject1 3 proj7")}</p>
              </li>
              <li>
                <p>{t("subject1 4 proj7")}</p>
              </li>
              <li>
                <p>{t("subject1 5 proj7")}</p>
              </li>
              <li>
                <p>{t("subject1 6 proj7")}</p>
              </li>
              <li>
                <p>{t("subject1 13 proj1")}</p>
              </li>
              <li>
                <p>{t("subject1 14 proj1")}</p>
              </li>
              <li>
                <p>{t("subject1 15 proj1")}</p>
              </li>
              <li>
                <p>{t("subject1 16 proj1")}</p>
              </li>
              <li>
                <p>{t("subject1 17 proj1")}</p>
              </li>
              <li>
                <p>{t("subject1 18 proj1")}</p>
              </li>
            </ul>
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
};
