import React from "react";
import { Navbar } from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import FormHelp from "../components/FormHelp/FormHelp";
import Requisites from "../components/Requisites";
import "../Projects.css";
import { t } from "i18next";
import { baseUrl } from "../config";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export const Project1 = () => {
  function openRequisites() {
    const homepage = document.getElementById("homepage");
    const formRequisites = document.getElementById("requisitesForm");
    formRequisites.classList.add("open-form");
    formRequisites.classList.remove("close-form");
    homepage.classList.add("blur-background");
  }
  return (
    <div className="page">
      <FormHelp />
      <Requisites />
      <Navbar color="menu-white-wrap" />
      <div className="project-head container">
        <div className="left-head-proj">
          <h3 className="section-title-text py-5">{t("name title proj1")}</h3>
          <button className="btn-take-part" onClick={openRequisites}>
            {t("btn get help")}
          </button>
        </div>
        <div className="right-head-proj">
          <img src={baseUrl + "/harkivhum1.jpg"} alt="photo help people" />
        </div>
      </div>
      <div className="project-wrap-page container">
        <Row className="project-page-row">
          <Col md className="project-page-col">
            <p>
              <b> {t("The purpose of the project")}</b> {t("goal proj1")}
            </p>
            <p>
              <b> {t("Target population")}</b> {t("target proj1")}
            </p>
            <p>
              <b> {t("Project implementation period")}</b> {t("time proj1")}
            </p>
            <p>
              <b> {t("International donor of the project")}</b>
              <ul>
                <li>{t("partner 1 title")}</li>
                <li>{t("partner 4 title")}</li>
              </ul>
            </p>
            <p>
              <b>
                {" "}
                {t("Population centers in which the project is implemented")}
              </b>
              <ul>
                <li> {t("cities proj1")}</li>
                <li> {t("cities1 proj1")}</li>
              </ul>
            </p>
            <p>
              <b>
                {t(
                  "The number of households to whom it is planned to provide humanitarian aid"
                )}
              </b>{" "}
              {t("number house proj1")}
            </p>
            <p>
              <b>
                {t(
                  "The number of people who are planned to be provided with food and non-food aid"
                )}
              </b>{" "}
              {t("number people proj1")}
            </p>
            <p>
              <b>{t("Partners for project implementatio")}</b>
              <ul>
                <li>{t("partner 2 title")}</li>
                <li>{t("partner 3 title")}</li>
                <li>{t("partner 8 title")}</li>
                <li>{t("partner 10 title")}</li>
              </ul>
            </p>
          </Col>
          <Col md className="project-page-col grey-block">
            <p>
              <b> {t("Subject of distribution")}</b> {t("subject proj1")}
            </p>

            <ul>
              <li>
                <p>{t("subject1 proj1")}:</p>
                <ul className="list-style-simple">
                  <li>{t("subject1 1 proj1")}</li>
                  <li>{t("subject1 2 proj1")}</li>
                  <li>{t("subject1 3 proj1")}</li>
                  <li>{t("subject1 4 proj1")}</li>
                  <li>{t("subject1 5 proj1")}</li>
                  <li>{t("subject1 6 proj1")}</li>
                  <li>{t("subject1 7 proj1")}</li>
                  <li>{t("subject1 8 proj1")}</li>
                  <li>{t("subject1 9 proj1")}</li>
                  <li>{t("subject1 10 proj1")}</li>
                </ul>
              </li>

              <li>
                <p>{t("subject1 12 proj1")}</p>
              </li>
              <li>
                <p>{t("subject1 13 proj1")}</p>
              </li>
              <li>
                <p>{t("subject1 14 proj1")}</p>
              </li>
              <li>
                <p>{t("subject1 15 proj1")}</p>
              </li>
              <li>
                <p>{t("subject1 16 proj1")}</p>
              </li>
              <li>
                <p>{t("subject1 17 proj1")}</p>
              </li>
              <li>
                <p>{t("subject1 18 proj1")}</p>
              </li>
            </ul>
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
};
