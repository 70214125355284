import "../Map.css";
import React from "react";
import { Link } from "react-router-dom";
import { t } from "i18next";

export default function ProjectItem({ item }) {
  const { title, description, img, linkTo } = item;
  return (
    <div className="project-item">
      <h3>{t(title)}</h3>
      <div className="project-info">
        <img src={img} alt="project" />
        <div className="map-proj-info">
          <p>{t(description)}</p>
          <Link
            to={`/project/project${linkTo}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="btn-req-support btn-proj-map">
              {t("btn projects")}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
