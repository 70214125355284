import React from "react";
import "../App.css";
import Header from "../components/header/Header";
import Main from "../components/mainhome/Main";
import Footer from "../components/footer/Footer";
import FormHelp from "../components/FormHelp/FormHelp";
import Requisites from "../components/Requisites";

export const Home = () => {
  const homepage = document.getElementById("homepage");

  function openFormHelp() {
    const formHelp = document.getElementById("contact-block");
    formHelp.classList.add("open-form");
    formHelp.classList.remove("close-form");
    homepage.classList.add("blur-background");
  }

  function openRequisites() {
    const formRequisites = document.getElementById("requisitesForm");
    formRequisites.classList.add("open-form");
    formRequisites.classList.remove("close-form");
    homepage.classList.add("blur-background");
  }

  return (
    <div className="Home page">
      <FormHelp />
      <Requisites />
      <div id="homepage" className="">
        <Header />
        <Main />
        <Footer />
      </div>
    </div>
  );
};
