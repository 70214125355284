import "./App.css";
import React from "react";
import { Home } from "./pages/Home";
import { Project } from "./pages/Project";
import { Reports } from "./pages/Reports";
import { About } from "./pages/About";
import { Project1 } from "./pages/Project1";
import { Project2 } from "./pages/Project2";
import { Project3 } from "./pages/Project3";
import { Project4 } from "./pages/Project4";
import { Project5 } from "./pages/Project5";
import { Project6 } from "./pages/Project6";
import { Project7 } from "./pages/Project7";
import { Project8 } from "./pages/Project8";
import { Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";

function App() {
  const { t } = useTranslation();

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="project" element={<Project />} />
        <Route path="reports" element={<Reports />} />
        <Route path="about" element={<About />} />
        <Route path="project/Project1" element={<Project1 />} />
        <Route path="project/Project2" element={<Project2 />} />
        <Route path="project/Project3" element={<Project3 />} />
        <Route path="project/Project4" element={<Project4 />} />
        <Route path="project/Project5" element={<Project5 />} />
        <Route path="project/Project6" element={<Project6 />} />
        <Route path="project/Project7" element={<Project7 />} />
        <Route path="project/Project8" element={<Project8 />} />
      </Routes>
    </>
  );
}

export default App;
