import Carousel from "react-bootstrap/Carousel";
import React from "react";
import { baseUrl } from "../../config";
import "./Reportsblock.css";
import { t } from "i18next";

function UncontrolledExample() {
  return (
    <Carousel
      className="slider-report"
      prevIcon={
        <span aria-hidden="true" className="carousel-control-prev-icon">
          <img src={baseUrl + "/sliderPrev.png"} alt="report slider prev" />
        </span>
      }
      nextIcon={
        <span aria-hidden="true" className="carousel-control-prev-icon">
          <img src={baseUrl + "/slideNext.png"} alt="report slider next" />
        </span>
      }
    >
      <Carousel.Item>
        <div className="report-slider-item">
          <div className="report-info">
            <h3 className="">{t("zvit 3 title")}</h3>
            <p className="my-1">{t("zvit 3 description")}</p>
            <a
              href="https://www.facebook.com/groups/neboukrainu/posts/804355370554034/"
              className="btn-req-support w-50 mt-5"
              target="_blank"
              rel="noreferrer"
            >
              {t("zvit btn link")}
            </a>
          </div>
          <div className="report-image pics-reports">
            <img
              className="d-block w-100 img-fluid"
              src={baseUrl + "/zvit3.jpg"}
              alt="First slide"
            />
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="report-slider-item">
          <div className="report-info">
            <h3 className="">{t("zvit 2 title")}</h3>
            <p className="my-1">{t("zvit 2 description")}</p>
            <a
              href="https://www.facebook.com/groups/neboukrainu/posts/967645974224972/"
              className="btn-req-support w-50 mt-5"
              target="_blank"
              rel="noreferrer"
            >
              {t("zvit btn link")}
            </a>
          </div>
          <div className="report-image pics-reports">
            <img
              className="d-block w-100 img-fluid"
              src={baseUrl + "/zvit2_1.jpg"}
              alt="First slide"
            />
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="report-slider-item">
          <div className="report-info">
            <h3 className="">{t("zvit 6 title")}</h3>
            <p className="my-1">{t("zvit 6 description")}</p>
            <a
              href="https://www.facebook.com/groups/neboukrainu/posts/967115354278034/"
              className="btn-req-support w-50 mt-5"
              target="_blank"
              rel="noreferrer"
            >
              {t("zvit btn link")}
            </a>
          </div>
          <div className="report-image">
            <img
              className="d-block w-100 img-fluid"
              src={baseUrl + "/zvit6_1.jpg"}
              alt="First slide"
            />
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
}

export default UncontrolledExample;
