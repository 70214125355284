import React, { useEffect, useState } from "react";
import data from "../../trans/reports.json";
import Uk from "@svg-maps/ukraine";
import { SVGMap } from "react-svg-map";
import Ukraine from "../../assets/ukraine.png";
import ReportItemMap from "./ReportItemMap";
import { t } from "i18next";
import "./Reportsblock.css";

export default function ReportsMap() {
  let [stateCode, setStateCode] = useState("kherson");
  let [stateData, setStateData] = useState(data[stateCode]);
  const list = stateData.map((item, key) => (
    <ReportItemMap item={item} key={key} />
  ));

  useEffect(() => {
    let kyiv = document.getElementById("kyiv-city");
    let kyivName = document.getElementById("kyivNameReport");

    const handleResize = () => {
      let kyivCoord = kyiv.getBoundingClientRect();
      console.log(kyivCoord);
      const x = kyivCoord.left + window.pageXOffset;
      const y = kyivCoord.top + window.pageYOffset;
      kyivName.style.left = x + "px";
      kyivName.style.top = y + "px";
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  function onLocationClick(event) {
    setStateCode(event.target.id);
    setStateData(data[event.target.id]);
  }
  return (
    <div className="MapReport container">
      <h3 className="section-title-text py-5">
        <div className="title-line"></div>
        {t("map title")}
      </h3>
      <div className="MapUkr">
        <div className="map-pic">
          <div className="ukraine">
            <img src={Ukraine} alt="ukraine" />
          </div>
          <div id="kyivNameReport">{t("Kyiv")}</div>
          <SVGMap map={Uk} onLocationClick={onLocationClick} />
        </div>
        <div className="map-info-report">
          <h3>{t("map projects title")}</h3>
          <h2 className="my-4">{t(stateCode)}</h2>
          <div className="projects-info">{list}</div>
        </div>
      </div>
    </div>
  );
}
