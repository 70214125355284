import React from "react";
import regionsData from "../map/MapProjects.json";
import "./CurrentProjects.css";
import { t } from "i18next";
import SliderCurrentProject from "./SliderCurrentProject";

export default function CurrentProjects() {
  let projects = regionsData;
  let regionsProj = [];
  let currentProj = [];

  for (let regProj in projects) {
    let projData = projects[regProj];
    if (projData.length !== 0) {
      regionsProj.push(projData);
    }
  }

  for (let proj in regionsProj) {
    let value = regionsProj[proj];
    for (let i in value) {
      currentProj.push(value[i]);
    }
  }

  return (
    <div className="CurrentProjects">
      <h3 className="section-title-text py-5">
        <div className="title-line"></div>
        {t("actual projects title")}
      </h3>
      <SliderCurrentProject currentProj={currentProj} />
    </div>
  );
}
